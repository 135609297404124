<template>
  <BaseCard class="card-bg-image px-10 py-8">
    <!-- Top Section -->
    <div class="border-b border-neutral-50 border-opacity-80 pb-4">
      <!-- Title/Buttons -->
      <div class="flex items-center justify-between w-full">
        <!-- Title -->
        <div class="flex mr-2 w-full overflow-hidden">
          <!-- Brief Header Icon -->
          <img
            style="width: 40px"
            class="mr-2"
            src="../../assets/images/brief-icon-black-white.png"
          >
          <!-- Title -->
          <contenteditable
            v-model="currentName"
            class="text-display-xs text-text-loud whitespace-nowrap overflow-ellipsis font-medium caret-primary-green-100 rounded focus:bg-transparent focus:outline-none focus:ring-0 focus:border-none hover:bg-neutral-25 hover:bg-opacity-80 hover:outline-none hover:ring-0 hover:border-none flex-grow"
            tag="div"
            :contenteditable="true"
            :no-html="true"
            @keyup="emitUpdate('name', $event.target.innerText)"
          />
        </div>
        <!-- Buttons -->
        <div class="flex gap-2">
          <a
            v-if="briefId"
            :href="`/share/briefs/${briefId}`"
            target="_blank"
          >
            <BaseButton outlined>
              <BaseText
                type="label"
                size="sm"
              > Preview </BaseText>
            </BaseButton>
          </a>

          <BaseButton
            v-clipboard:copy="getShareLink"
            v-clipboard:success="handleClipboardCopy"
            primary
          >
            <BaseText
              type="label"
              size="sm"
            >
              Share
            </BaseText>
          </BaseButton>
        </div>
      </div>
    </div>

    <!-- Bottom Section -->
    <div class="mt-4">
      <!-- Location -->
      <div class="flex items-center h-7 mb-2">
        <div class="text-text-normal w-28 flex items-center">
          <img
            src="../../assets/icons/folder.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Location
          </BaseText>
        </div>

        <div class="text-text-muted">
          <BaseSelect
            :selected.sync="selectedFolders"
            text-trigger
            item-value="id"
            :items="getFolders"
            @select="handleMoveBrief"
          >
            <BaseText
              slot="reference"
              type="body"
              size="sm"
            >
              {{ selectedFolders[0].name }}
            </BaseText>

            <template #item="{ item }">
              <div class="flex">
                <img
                  class="pr-2"
                  src="../../assets/icons/folder.svg"
                >
                <BaseText
                  type="body"
                  size="sm"
                >
                  {{ item.name }}
                </BaseText>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>

      <!-- Status -->
      <div class="flex items-center h-7 mb-2">
        <div class="text-text-normal w-28 flex items-center">
          <img
            src="../../assets/icons/loader.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Status
          </BaseText>
        </div>

        <div class="text-text-muted">
          <BaseSelect
            :selected.sync="selectedStatus"
            text-trigger
            :items="[
              {
                itemText: 'To Do',
                value: 'to-do',
              },
              {
                itemText: 'In Progress',
                value: 'in-progress',
              },
              {
                itemText: 'Needs Review',
                value: 'needs-review',
              },
              {
                itemText: 'Completed',
                value: 'completed',
              },
            ]"
            @select="emitUpdate('status', selectedStatus[0].itemText)"
          >
            <!-- Reference point -->
            <BaseBadge
              slot="reference"
              :blue="selectedStatus[0].value === 'in-progress'"
              :green="selectedStatus[0].value === 'completed'"
              :yellow="selectedStatus[0].value === 'needs-review'"
              :gray="selectedStatus[0].value === 'to-do'"
              dot
            >
              <BaseText
                type="label"
                size="xs"
              >
                {{ selectedStatus[0].itemText }}
              </BaseText>
            </BaseBadge>

            <!-- Item Slot -->
            <template #item="{ item }">
              <BaseBadge
                :blue="item.value === 'in-progress'"
                :green="item.value === 'completed'"
                :yellow="item.value === 'needs-review'"
                :gray="item.value === 'to-do'"
                dot
              >
                <BaseText
                  type="label"
                  size="xs"
                >
                  {{ item.itemText }}
                </BaseText>
              </BaseBadge>
            </template>
          </BaseSelect>
        </div>
      </div>

      <!-- Due Date -->
      <div class="flex items-center h-7 mb-2">
        <div class="text-text-normal w-28 flex items-center">
          <img
            src="../../assets/icons/calendar.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Due Date
          </BaseText>
        </div>

        <div class="text-text-muted">
          <BaseCalendar
            :due-date="dueDate"
            @update="(value) => emitUpdate('dueDate', value)"
          />
        </div>
      </div>

      <!-- Description -->
      <div
        class="flex items-start mb-2"
        style="min-height: 1.75rem"
      >
        <div class="text-text-normal w-28 flex items-center">
          <img
            src="../../assets/icons/pencil.svg"
            class="mr-1.5"
          >
          <BaseText
            type="body"
            size="sm"
          >
            Description
          </BaseText>
        </div>

        <div class="text-text-muted w-full ml-3">
          <bubble-menu
            v-if="editor"
            class="border p-1 rounded bg-white grid grid-cols-7 gap-1"
            :editor="editor"
            :tippy-options="{ duration: 100 }"
          >
            <!-- Bold -->
            <button
              :class="{ 'is-active': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <!-- Bold Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-bold`" />
              </svg>
            </button>

            <!-- Italics -->
            <button
              :class="{ 'is-active': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <!-- Italic Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-italic`" />
              </svg>
            </button>

            <!-- Hyperlink -->
            <button
              :class="{ 'is-active': editor.isActive('link') }"
              @click="editor.chain().focus().toggleLink().run()"
            >
              <!-- Link Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-link`" />
              </svg>
            </button>

            <!-- Divider -->
            <div class="text-gray-400 w-full text-center">
              |
            </div>

            <!-- H1 -->
            <button
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
              <!-- H1 Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-h-1`" />
              </svg>
            </button>

            <!-- H2 -->
            <button
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
              <!-- H2 Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-h-2`" />
              </svg>
            </button>

            <!-- H3 -->
            <button
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
              <!-- H2 Icon -->
              <svg class="remix h-4 w-4">
                <use :xlink:href="`${remixiconUrl}#ri-h-3`" />
              </svg>
            </button>
          </bubble-menu>
          <editor-content
            :editor="editor"
            class="editor__content"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

// TipTap
import StarterKit from '@tiptap/starter-kit'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import Heading from '@tiptap/extension-heading'

export default {
  name: 'BriefsHeader',
  components: {
    EditorContent,
    BubbleMenu
  },
  props: {
    name: {
      type: String,
      default: () => 'Nuun Sport Hydration'
    },
    // Due Date
    dueDate: {
      type: Number,
      default: () => +new Date()
    },
    // Description
    description: {
      type: String,
      default: () => ''
    },
    // Location
    folder: {
      type: Object,
      default: () => {}
    },
    // Status
    status: {
      type: Array,
      default: () => []
    },
    createdAt: {
      type: Number,
      default: () => +new Date()
    },
    briefId: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      selectedStatus: [
        {
          itemText: 'To Do',
          value: 'to-do'
        }
      ],
      selectedFolders: [
        {
          name: 'Default Folder',
          id: 'default'
        }
      ],
      timeoutId: null,
      selectedDueDate: +new Date(),
      currentName: '',
      currentDescription: '',

      // Tiptap
      editor: null,
      isEditable: true,
      remixiconUrl,
      editorValue: null
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getFolders']),
    getShareLink () {
      return process.env.VUE_APP_BASE_URL + '/share/briefs/' + this.briefId
    }
  },
  mounted () {
    // Set initial values then update them if props are passed in

    // Set due date
    this.selectedDueDate = this.dueDate || +new Date()

    // Set Description
    this.currentDescription = this.description

    // Set Status
    this.selectedStatus = this.status

    // Set Title
    this.currentName = this.name

    // Set the current folder
    this.selectedFolders = [this.folder]

    // TIPTAP

    // Make this work with brief without HTML)
    let value = this.description || '<p></p>'

    if (this.description && !this.description.includes('<p>')) {
      value = `<p>${this.description}</p>`
    }

    this.editorValue = value

    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        Heading.configure({
          levels: [1, 2, 3],
          HTMLAttributes: {
            class: 'heading-class'
          }
        })
      ],
      content: this.editorValue,
      onUpdate: () => {
        const currentEditorValue = this.editor.getHTML()
        this.editorValue = currentEditorValue

        this.emitUpdate('notes', currentEditorValue)
      }
    })
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchFolders']),
    handleClipboardCopy () {
      // Track when Brief is Shared
      window.analytics.track('Brief Shared', {
        briefTitle: this.name,
        briefId: this.briefId
      })
      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    emitUpdate (section, value) {
      if (this.name === 'Untitled Brief') {
        if (this.timeoutId) clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          window.analytics.track('Brief Updated', {
            briefTitle: this.currentName,
            briefId: this.briefId
          })
        }, 5000)
      }
      const savePayload = {
        section: section,
        value: value
      }
      console.log('savePayload', savePayload)
      this.$emit('saveSection', savePayload)
    },
    async handleMoveBrief () {
      this.loading = true

      // Remove board from old folders boardIds (If not the default folder)
      if (this.folder.id !== 'default') {
        const toRemovePayload = {
          briefIds: this.folder.briefIds.filter((id) => id !== this.briefId)
        }

        await FirebaseAPI.Folders.update(this.folder.id, toRemovePayload)
      }

      // Only add a folder if Default isn't selected
      if (this.selectedFolders[0].id !== 'default') {
        // Add brief to new folder
        const toAddPayload = {
          briefIds: [...(this.selectedFolders[0].briefIds || []), this.briefId]
        }

        await FirebaseAPI.Folders.update(
          this.selectedFolders[0].id,
          toAddPayload
        )

        this.$emit('update')
      }

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loading = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="css">
.card-bg-image {
  /* Important to overwrite the default bg classes */
  background-image: url("../../assets/images/green-glow.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.editor__content {
  min-height: 1em;
  height: auto;
  overflow: hidden;
}

.editor__content > div {
  height: auto;
}
</style>
